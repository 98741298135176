<template>
  <div class="login">
    <h1 class="title fs-36">{{ $t("AUTHENTICATION.WELCOME_BACK") }}</h1>
    <p class="subtitle fs-12">{{ $t("AUTHENTICATION.WELCOME_SUBTITLE") }}</p>
    <div class="login__form">
      <FormValidation @handleSubmit="onSubmit">
        <b-form>
          <div class="login-form">
            <b-form-group>
              <TextField
                v-model="formValues.email"
                :label="$t('AUTHENTICATION.USER_NAME')"
                :name="$t('AUTHENTICATION.USER_NAME')"
                :placeholder="$t('AUTHENTICATION.USER_NAME_PLACEHOLDER')"
                rules="required|email"
              />
            </b-form-group>

            <b-form-group>
              <TextField
                v-model="formValues.password"
                :type="newPasswordType"
                :label="$t('AUTHENTICATION.PASSWORD')"
                :name="$t('AUTHENTICATION.PASSWORD')"
                class="p-relative d-block"
                :placeholder="$t('AUTHENTICATION.PASSWORD')"
                rules="required"
                ref="password"
              >
              <b-icon
                    :icon="newPasswordIcon"
                    @click="hideShowPassword('new')"
                    class="icon-password"
                  />
              </TextField>
            </b-form-group>

            <b-form-group>
              <Button
                type="submit"
                color="primary"
                :d-block="true"
                :loading="loading"
                custom-class="w-100 text-uppercase"
              >
                {{ $t("AUTHENTICATION.LOGIN") }}
              </Button>
            </b-form-group>
            <div class="login__remember">
              <p class="switch-label fs-16">{{ $t("AUTHENTICATION.REMEMBER_ME") }}</p>
              <ToggleButton
                @change="onChangeEventHandler"
                :value="isRemember"
                :sync="true"
                :width="43"
                :height="26"
                :color="{ checked: '#667085', unchecked: '#F2F4F7' }"
              />
            </div>
          </div>
        </b-form>
      </FormValidation>
    </div>
  </div>
</template>
<script>
import { ToggleButton } from "vue-js-toggle-button"
import { TogglePasswordMixins } from "@/mixins/TogglePasswordMixins"
export default {
  components: {
    ToggleButton
  },
  mixins: [TogglePasswordMixins],
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formValues: {
        email: "",
        password: ""
      },
      isRemember: false
    }
  },
  methods: {
    onSubmit() {
      this.$emit("handleLogin", this.formValues)
    },
    onChangeEventHandler(e){
       if(e.value == true) this.isRemember = true
       else this.isRemember = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index";
</style>
