export const postLoginRequest = (data) => ({
  method: "post",
  url: "login",
  config: {
    data
  }
})

export const postSignOutRequest = () => ({
  method: "post",
  url: "sign-out",
})

export const postChangePasswordRequest = (data) => ({
  method: "post",
  url: "change-password",
  config: {
    data
  }
})

