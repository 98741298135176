<template>
  <AuthLayout>
    <template #cardBody>
      <Login :loading="loading" @handleLogin="handleLogin" />
    </template>
  </AuthLayout>
</template>

<script>
import Login from "@/components/Modules/Registeration/Login/index.vue"
import { postLoginRequest } from "@/api/login.js"
import VueCookies from "vue-cookies"
import { mapActions } from "vuex"
export default {
  components: {
    Login
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    ...mapActions(["setUser", "ShowToast", "initConnection"]),
    handleLogin(loginData) {
      this.loading = true
      this.ApiService(postLoginRequest(loginData))
        .then((res) => {
          if (res.data.status == "success") {
            if(res.data.user.status == 1){
              let payLoad = {}
              // payLoad.user = res.data.data
              payLoad.token = res.data.access_token
              // payLoad.image = res.data.data.image
              this.setUser(payLoad)
              this.initConnection()
            }else{
              this.ShowToast({
                title: this.$t("GLOBAL.GLOBAL_FAILED"),
                type: "danger",
                message: this.$t("This user is not active")
              })
            }
          } else {
            this.ShowToast({
              title: this.$t("GLOBAL.GLOBAL_FAILED"),
              type: "danger",
              message: this.$t("AUTHENTICATION.ERROR_AUTH")
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index";
</style>
